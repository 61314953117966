.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 100%;
}

.button:hover {
  background: #000000;
  color: #ffffff;
}

.button:focus {
  outline: 2px solid blue;
}

.button_disabled {
  background-color: #ccc;
  cursor: not-allowed;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 100%;
}

.button_loading {
  cursor: wait;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 100%;
}

.button_index {
}